import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import s from './Test.module.scss';
import { dataStr, dataFiles } from '../../Utils/mockData';
import * as utils from '../../Utils/utils';

const useStyles = makeStyles({
  table: {
    minWidth: 320
  }
});

const Test = () => {
  const classes = useStyles();

  return (
    <div className={s.main}>
      <Typography variant="h5">Для файлов</Typography>
      <TableContainer component={Paper} style={{ marginTop: '50px' }}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ fontWeight: '800' }}>
                #
              </TableCell>
              <TableCell align="left" style={{ fontWeight: '800' }}>
                Исходное
              </TableCell>
              <TableCell align="left" style={{ fontWeight: '800' }}>
                Ожидаемое
              </TableCell>
              <TableCell align="left" style={{ fontWeight: '800' }}>
                Обработанное
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataFiles.map((row, index) => {
              const data = utils.handleInputFiles(row.before);
              const mod = utils.joinArrMod(data.mod, ' ');
              return (
                <TableRow className={s.table} key={row.after}>
                  <TableCell align="left">{index + 1}</TableCell>
                  <TableCell align="left">
                    <pre>{row.before.map(i => `${i.name}\n`)}</pre>
                  </TableCell>
                  <TableCell align="left">{row.after}</TableCell>
                  <TableCell
                    align="left"
                    style={{
                      backgroundColor: mod === row.after ? '#81c784' : '#e57373'
                    }}
                  >
                    {mod}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h5">Для строки</Typography>
      <TableContainer component={Paper} style={{ marginTop: '50px' }}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ fontWeight: '800' }}>
                #
              </TableCell>
              <TableCell align="left" style={{ fontWeight: '800' }}>
                Исходное
              </TableCell>
              <TableCell align="left" style={{ fontWeight: '800' }}>
                Ожидаемое
              </TableCell>
              <TableCell align="left" style={{ fontWeight: '800' }}>
                Обработанное
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataStr.map((row, index) => {
              const data = utils.handleInputStr(row.before);
              const mod = utils.joinArrMod(data.mod, ' ');
              return (
                <TableRow className={s.table} key={row.after}>
                  <TableCell align="left">{index + 1}</TableCell>
                  <TableCell align="left">
                    <pre>{row.before}</pre>
                  </TableCell>
                  <TableCell align="left">{row.after}</TableCell>
                  <TableCell
                    align="left"
                    style={{
                      backgroundColor: mod === row.after ? '#81c784' : '#e57373'
                    }}
                  >
                    {mod}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Test;
