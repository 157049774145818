import React, { useState } from 'react';
import { connect } from 'react-redux';
import { StateContext, stateApp } from '../../Context/StateContext';

import InputFiles from '../../Components/InputFiles/InputFiles';

const Main = () => {
  const [statePovider, setStateProvider] = useState(stateApp);
  const changeMinNumber = n => {
    setStateProvider({ ...statePovider, minNumber: n });
  };
  const changeMinDigits = n => {
    setStateProvider({ ...statePovider, minDigits: n });
  };
  const changeExcludeNumbering = b => {
    setStateProvider({ ...statePovider, excludeNumbering: b });
  };

  return (
    <StateContext.Provider
      value={{
        ...statePovider,
        changeMinNumber,
        changeMinDigits,
        changeExcludeNumbering
      }}
    >
      <InputFiles />
    </StateContext.Provider>
  );
};
const mapStateToProps = state => ({
  state
});

export default connect(mapStateToProps)(Main);
