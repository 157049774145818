import React from 'react';
import Typography from '@material-ui/core/Typography';

import s from './Help.module.scss';

const Help = () => {
  return (
    <div className={s.main}>
      <Typography variant="h5" gutterBottom>
        Как пользоваться
      </Typography>
      <p
        className={s.video}
        dangerouslySetInnerHTML={{
          __html:
            '<iframe src="https://www.youtube.com/embed/sp3ITasczBs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />'
        }}
      />
      <Typography variant="h6" gutterBottom>
        Отбор фотографий из сообщений или из файлов
      </Typography>
      <p
        className={s.video}
        dangerouslySetInnerHTML={{
          __html:
            '<iframe src="https://www.youtube.com/embed/1mva6YZV330" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />'
        }}
      />
      <Typography variant="h6" gutterBottom>
        Создание маски, чтобы переносить на компьютер только выбранные равы, для
        экономии места
      </Typography>
      <p
        className={s.video}
        dangerouslySetInnerHTML={{
          __html:
            '<iframe src="https://www.youtube.com/embed/ZI00ZI9roBg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />'
        }}
      />
      <Typography variant="h6" gutterBottom>
        Отбор фото на iPhone
      </Typography>
    </div>
  );
};

export default Help;
