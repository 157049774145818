import React from 'react';
import { Box, Typography } from '@material-ui/core';

const Analysis = props => {
  const { filesArrMod } = props;

  if (!filesArrMod.origin.length) return null;

  const ruledOut = () => {
    return filesArrMod.origin.length > filesArrMod.mod.length ? (
      <>
        <br />
        {`Исключено: ${filesArrMod.origin.length - filesArrMod.mod.length}`}
        <Typography variant="subtitle2" gutterBottom>
          {filesArrMod.modNote
            .filter(i => /исключен:/.test(i))
            .map(i => (
              <span key={i}>
                {i}
                <br />
              </span>
            ))}
        </Typography>
      </>
    ) : null;
  };

  return (
    <Box variant="body1" gutterBottom style={{ marginTop: '40px' }}>
      <hr />
      {filesArrMod.mod.length > 0 &&
        `Отобрано имен файлов: ${filesArrMod.mod.length} из ${filesArrMod.origin.length}`}
      {ruledOut()}
      <hr />
    </Box>
  );
};

export default Analysis;
