import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Typography, Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { StateContext } from '../../Context/StateContext';

import s from './NumberSelect.module.scss';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 50,
    textAlign: 'center',
    marginRight: 20
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const NumberSelect = () => {
  const classes = useStyles();
  const {
    minNumber,
    minDigits,
    excludeNumbering,
    changeMinNumber,
    changeMinDigits,
    changeExcludeNumbering
  } = useContext(StateContext);

  const handleChange = event => {
    changeMinNumber(event.target.value);
  };
  const items = [1, 2, 3, 4, 5];
  const minDigitsArr = [0, 1, 2, 3, 4, 5, 6, 7];
  return (
    <div className={s.main}>
      <Box>
        <Typography variant="body1">
          Исключить варианты, где количество цифр меньше:
        </Typography>
        <FormControl className={classes.formControl}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={minNumber}
            onChange={handleChange}
          >
            {items.map(i => (
              <MenuItem value={i} key={i}>
                {i}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <Typography variant="body1">
          Минимальное количество цифр в числе:
        </Typography>
        <FormControl className={classes.formControl}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={minDigits}
            onChange={e => changeMinDigits(e.target.value)}
          >
            {minDigitsArr.map(i => (
              <MenuItem value={i} key={i}>
                {i === 0 ? 'Авто' : i}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <FormControlLabel
          value="start"
          control={
            <Checkbox
              color="primary"
              checked={excludeNumbering}
              onClick={() => changeExcludeNumbering(!excludeNumbering)}
            />
          }
          label="Исключить порядковые номера"
          // labelPlacement="start"
        />
      </Box>
    </div>
  );
};

export default NumberSelect;
