import React, { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import s from './InputFiles.module.scss';
import NameTable from '../NameTable/NameTable';
import CommanderMask from '../CommanderMask/CommanderMask';
import * as utils from '../../Utils/utils';
import Analysis from '../Analysis/Analysis';
import NumberSelect from '../NumberSelect/NumberSelect';
import { StateContext } from '../../Context/StateContext';
import InputTextSection from '../InputTextSection/InputTextSection';

const InputFiles = () => {
  const [filesArrMod, setFilesArrMod] = useState({
    origin: [],
    modNote: [],
    mod: []
  });
  const [files, setFiles] = useState([]);
  const [textInput, setTextInput] = useState(
    localStorage.getItem('inputText') ? localStorage.getItem('inputText') : ''
  );
  const [mode, setMode] = useState(
    localStorage.getItem('mode') ? localStorage.getItem('mode') : 'text'
  );

  const { minNumber, minDigits, excludeNumbering } = useContext(StateContext);

  useEffect(() => {
    setFilesArrMod(utils.handleInputFiles(files));
  }, [files]);

  useEffect(() => {
    if (mode === 'files') return;

    setFilesArrMod(
      utils.handleInputStr(textInput, minNumber, minDigits, excludeNumbering)
    );
  }, [textInput, minNumber, minDigits, excludeNumbering, mode]);

  // files [{name: 'str'}, ...]
  const fileHandling = f => {
    setFiles(prev => [...prev, ...f]);
  };

  // Нарочно отклоняем все файлы (по расширению и размеру) чтобы не инициировать процесс их обработки браузером, чтобы не нагружать систему.
  const rejectedHandling = f => {
    setFiles(prev => [...prev, ...f]);
  };

  const changeText = e => {
    const text = e.target.value;
    setTextInput(text);
    localStorage.setItem(`inputText`, text);
  };

  const clear = () => {
    setTextInput('');
    localStorage.setItem(`inputText`, '');
  };

  const changeRadio = e => {
    setFilesArrMod({
      origin: [],
      modNote: [],
      mod: []
    });
    setMode(e.target.value);
    localStorage.setItem(`mode`, e.target.value);
  };

  return (
    <div className={s.main}>
      <RadioGroup
        row
        aria-label="position"
        name="position"
        defaultValue={mode}
        className={s.radioGroup}
      >
        <FormControlLabel
          value="files"
          control={<Radio color="primary" />}
          label="Файлы"
          labelPlacement="end"
          onChange={changeRadio}
        />
        <FormControlLabel
          value="text"
          control={<Radio color="primary" />}
          label="Текст"
          labelPlacement="end"
          onChange={changeRadio}
        />
      </RadioGroup>
      {mode === 'files' ? (
        <>
          <DropzoneAreaBase
            acceptedFiles={['*.xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx']}
            dropzoneText="Перетащите сюда изображение или кликните для выбора файлов"
            maxFileSize={1}
            filesLimit={10000}
            showAlerts={false}
            onDrop={f => fileHandling(f)}
            onDropRejected={f => rejectedHandling(f)}
          />
          <div className={s.controlSection}>
            {filesArrMod.origin.length > 0 && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setFiles([]);
                  setFilesArrMod({
                    origin: [],
                    modNote: [],
                    mod: []
                  });
                }}
              >
                Очистить
              </Button>
            )}
          </div>
        </>
      ) : (
        <>
          <NumberSelect />
          <InputTextSection
            textInput={textInput}
            changeText={changeText}
            filesArrMod={filesArrMod}
          />
          <div className={s.controlSection}>
            {textInput && (
              <Button variant="outlined" color="primary" onClick={clear}>
                Очистить
              </Button>
            )}
          </div>
        </>
      )}
      <CommanderMask filesArrMod={filesArrMod.mod} />
      <Analysis filesArrMod={filesArrMod} />
      <NameTable filesArrMod={filesArrMod} />
    </div>
  );
};

export default InputFiles;
