import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import s from './Header.module.scss';

const useStyles = makeStyles({
  list: {
    width: 250
  }
});

export default function TemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState(false);

  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState(open);
  };

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Link href="/">
        <List>
          <ListItem>
            <ListItemText primary="Главная" />
          </ListItem>
        </List>
      </Link>
      <Link href="/help">
        <List href="/help">
          <ListItem>
            <ListItemText primary="Как пользоваться" href="/help" />
          </ListItem>
        </List>
      </Link>
    </div>
  );

  return (
    <div className={s.moboleMenu}>
      <IconButton
        onClick={toggleDrawer(true)}
        aria-label="menu"
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </div>
  );
}
