import React, { useEffect, useState } from 'react';
import {
  Typography,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import useClipboard from 'react-use-clipboard';
import macImg from '../../Resources/mac.jpg';
import winImg from '../../Resources/win.jpg';
import tcImg from '../../Resources/tc.png';
import lrImg from '../../Resources/lr.png';

import s from './CommanderMask.module.scss';

const Lr = () => (
  <div className={s.itemRadioGroup}>
    <img src={lrImg} alt="" className={s.img} />
    <Typography variant="body1">XXXX XXXX</Typography>
  </div>
);

const Mac = () => (
  <div className={s.itemRadioGroup}>
    <img src={macImg} alt="" className={s.img} />
    <Typography variant="body1">XXXX OR XXXX</Typography>
  </div>
);

const Win = () => (
  <div className={s.itemRadioGroup}>
    <img src={winImg} alt="" className={s.img} />
    <Typography variant="body1">*XXXX* OR *XXXX*</Typography>
  </div>
);

const Tc = () => (
  <div className={s.itemRadioGroup}>
    <img src={tcImg} alt="" className={s.img} />
    <Typography variant="body1">*XXXX*;*XXXX*</Typography>
  </div>
);

const CommanderMask = props => {
  const { filesArrMod } = props;
  const [mask, setMask] = useState('');
  const [value, setValue] = useState('lr');
  const [separator, setSeparator] = useState(',');
  const [isCopied, setCopied] = useClipboard(mask, {
    successDuration: 2000
  });

  const handleChange = e => {
    setValue(e.target.value);
  };

  const changeSeparator = e => {
    if (value !== 'separator') {
      setValue('separator');
    }
    setSeparator(e.target.value);
  };

  useEffect(() => {
    if (filesArrMod.length) {
      switch (value) {
        case 'lr':
          setMask(`${filesArrMod.join(' ')}`);
          break;
        case 'mac':
          setMask(`${filesArrMod.join(' OR ')}`);
          break;
        case 'win':
          setMask(`*${filesArrMod.join('* OR *')}*`);
          break;
        case 'tc':
          setMask(`*${filesArrMod.join('*;*')}*`);
          break;
        case 'separator':
          setMask(`${filesArrMod.join(separator)}`);
          break;
        default:
          setMask('');
          break;
      }
    }
  }, [filesArrMod, value, separator]);

  if (!filesArrMod.length) return null;

  return (
    <div className={s.main}>
      <RadioGroup
        value={value}
        onChange={handleChange}
        className={s.radioGroupTop}
      >
        <FormControlLabel
          value="lr"
          control={<Radio color="primary" />}
          label={<Lr />}
        />
        <FormControlLabel
          value="win"
          control={<Radio color="primary" />}
          label={<Win />}
        />
        <FormControlLabel
          value="mac"
          control={<Radio color="primary" />}
          label={<Mac />}
        />
        <FormControlLabel
          value="tc"
          control={<Radio color="primary" />}
          label={<Tc />}
        />
        <FormControlLabel
          value="separator"
          control={<Radio color="primary" />}
          label={
            <div className={s.itemRadioGroup}>
              <TextField
                value={separator}
                label="Разделитель"
                className={s.inputSeparator}
                onChange={changeSeparator}
              />
              <Typography variant="body1" className={s.strSeparator}>
                XXXX{separator}XXXX
              </Typography>
            </div>
          }
        />
      </RadioGroup>
      <div>
        <div className={s.copyToClipboardBtn}>
          <Button variant="contained" color="primary" onClick={setCopied}>
            Скопировать в буфер {isCopied ? '👍' : ''}
          </Button>
        </div>
        <Typography variant="body1" gutterBottom className={s.mask}>
          {mask}
        </Typography>
      </div>
    </div>
  );
};

export default CommanderMask;
