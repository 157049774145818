import React, { useState, useEffect } from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import s from './InputTextSection.module.scss';

const InputTextSection = props => {
  const { textInput, changeText, filesArrMod } = props;
  const [markContent, setMarkContent] = useState(textInput);

  useEffect(() => {
    const markOriginList = filesArrMod.origin;

    let result = '';

    // Исключить из подсвечивание то, что в квадратных скобках
    let squareBracket = false;

    for (
      let i = 0, markIndex = 0;
      i < textInput.length && markIndex < markOriginList.length;
      i += 1
    ) {
      if (textInput[i] === '[') {
        squareBracket = true;
      } else if (textInput[i] === ']') {
        squareBracket = false;
      }
      if (
        !squareBracket &&
        markOriginList[markIndex] ===
          textInput.slice(i, i + markOriginList[markIndex].length)
      ) {
        const markColor = /исключен:/.test(filesArrMod.modNote[markIndex])
          ? '#eea6a6'
          : '#aaebaa';
        result += `<mark style="background-color: ${markColor}" title="#${markIndex +
          1}">${markOriginList[markIndex]}</mark>`;
        i += markOriginList[markIndex].length - 1;
        markIndex += 1;
      } else {
        result += textInput[i];
      }
    }
    setMarkContent(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesArrMod]);

  return (
    <div className={s.main}>
      <pre
        className={s.markSection}
        dangerouslySetInnerHTML={{ __html: markContent }}
      />
      <TextareaAutosize
        value={textInput}
        aria-label="minimum height"
        rowsMin={15}
        placeholder="Вставьте сюда текст, который надо обработать"
        style={{ width: '100%' }}
        onChange={changeText}
        className={s.inputTextarea}
      />
    </div>
  );
};

export default InputTextSection;
