import React from 'react';
import { Typography, Box } from '@material-ui/core';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import vkIcon from '../../Resources/Icons/vk.png';
import telegramIcon from '../../Resources/Icons/telegram.png';

import s from './Footer.module.scss';

const Footer = () => (
  <footer>
    <div className={s.main}>
      <Typography variant="body1" gutterBottom>
        © 2021-2023 Ratelrc
      </Typography>
      <Box className={s.social}>
        <a
          href="https://vk.com/andrianovka"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={vkIcon} alt="vk" />
        </a>
        <a
          href="https://t.me/andrianovka"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={telegramIcon} alt="telegram" />
        </a>
      </Box>
      <Typography variant="body1" gutterBottom>
        <a
          href="https://yoomoney.ru/fundraise/MGWwrQMsBtE.230715"
          target="_blank"
          rel="noreferrer"
        >
          <AccountBalanceWalletIcon className={s.walletIcon} /> Поддержать
          проект
        </a>
      </Typography>
    </div>
  </footer>
);

export default Footer;
