import React from 'react';

import s from './Header.module.scss';
import NavigationBar from './NavigationBar';

const Header = () => {
  return (
    <header className={s.main}>
      <NavigationBar />
    </header>
  );
};

export default Header;
