const dataFiles = [
  {
    before: [
      { name: 'DSC03519.ARW' },
      { name: 'DSC03520.ARW' },
      { name: 'DSC03521.ARW' },
      { name: 'DSC03522.ARW' },
      { name: 'DSC03523.ARW' }
    ],
    after: `DSC03519 DSC03520 DSC03521 DSC03522 DSC03523`
  },
  {
    before: [
      { name: '8B8А3519.CR2' },
      { name: '8B8А3520.CR2' },
      { name: '8B8А3521.CR2' },
      { name: '8B8А3522.CR2' },
      { name: '8B8А3523.CR2' }
    ],
    after: `8B8А3519 8B8А3520 8B8А3521 8B8А3522 8B8А3523`
  },
  {
    before: [
      { name: 'файл.jpg' },
      { name: 'итог.jpg' },
      { name: 'рисунок.jpg' },
      { name: 'photo.jpg' }
    ],
    after: `файл итог рисунок photo`
  },
  {
    before: [
      { name: 'ORG_DSC05168.JPG' },
      { name: 'ORG_DSC05168 (1).JPG' },
      { name: 'ORG_DSC05168-[1].JPG' },
      { name: 'ORG_DSC05168-1.JPG' },
      { name: 'ORG_DSC07011.JPG' },
      { name: 'ORG_DSC07011 (1).JPG' },
      { name: 'ORG_DSC07011-[1].JPG' },
      { name: 'ORG_DSC07011-1.JPG' }
    ],
    after: `ORG_DSC05168 ORG_DSC05168 (1) ORG_DSC05168-[1] ORG_DSC05168-1 ORG_DSC07011 ORG_DSC07011 (1) ORG_DSC07011-[1] ORG_DSC07011-1`
  },
  {
    before: [{ name: '(12).jpg' }],
    after: `(12)`
  }
];

const dataStr = [
  {
    before: `848А3519 убрать морщинки
848А3520 убрать синяки
848А3521 сделать чуть моложе
848А3522 разгладить кожу
848А3523 убрать прыщик`,
    after: `848А3519 848А3520 848А3521 848А3522 848А3523`
  },
  {
    before: `DSC03519.ARW
DSC03520.ARW
DSC03521.ARW
DSC03522.ARW
DSC03523.ARW`,
    after: `DSC03519 DSC03520 DSC03521 DSC03522 DSC03523`
  },
  {
    before: `8B8А3520.CR2
8B8А3521.sr2
8B8А3522.cr3
8B8А3523.3fr
8B8А3524.rw2
8B8А3525.r3d
8B8А3526.x3f`,
    after: `8B8А3520 8B8А3521 8B8А3522 8B8А3523 8B8А3524 8B8А3525 8B8А3526`
  },
  {
    before: `3519
3520
3521
3522
3523`,
    after: `3519 3520 3521 3522 3523`
  },
  {
    before: `готовый.jpg
файл.jpg
итог.jpg
рисунок.jpg
photo.jpg`,
    after: ``
  },
  {
    before: `3285?, 3286***, 3287супер, 3288эта,`,
    after: `3285 3286 3287 3288`
  },
  {
    before: `мы выбрали фотки: 7, 25, 64, 525, 985, 989, 990`,
    after: `0007 0025 0064 0525 0985 0989 0990`
  },
  {
    before: `12, 012, 0012`,
    after: `0012`
  },
  {
    before: `1122	1136	1151	1115	
    3091		1039	1037	1040
    1227	1229	1082	1084`,
    after: `1122 1136 1151 1115 3091 1039 1037 1040 1227 1229 1082 1084`
  },
  {
    before: `4 25 326 4237 04238 004239`,
    after: `000004 000025 000326 004237 004238 004239`
  },
  {
    before: `[11:50, 26.07.2021] Демидов 1710 и 1706`,
    after: `1710 1706`
  },
  {
    before: `[11:50, 26.07.2021] Демидов qwe1710 и qwe1706\n[11:50, 26.07.2021] Демидов qwe1711 и qwe1707`,
    after: `qwe1710 qwe1706 qwe1711 qwe1707`
  },
  {
    before: `DSC01570.jpg DSC01571.jpg 1234 245`,
    after: `DSC01570 DSC01571 01234 00245`
  },
  {
    before: `12345/23456/34567/4567/`,
    after: `12345 23456 34567 04567`
  },
  {
    before: `1168, 1137

    https://cloud.mail.ru/public/D5rn%2FmdsdqPBv3hif\n1347, 1359, http://cloud.mail.ru/public/WkSg%2FEsafVAUmjNZe\n1348, 1360, ftp://cloud.mail.ru/public/WkSg%2FEsafVAUmjNZe\n1349 1361 ftps://cloud.mail.ru/public/WkSg%2FEsafVAUmjNZe\n1350 1362
    
     Ничего не выбрала, не понравились(
    `,
    after: `1168 1137 1347 1359 1348 1360 1349 1361 1350 1362`
  },
  {
    before: `1 2 3 4 5`,
    after: `0001 0002 0003 0004 0005`
  },
  {
    before: `1234;5577|4567\\7890/1011-1213,2345:7654; ;1923 / 7789`,
    after: `1234 5577 4567 7890 1011 1213 2345 7654 1923 7789`
  },
  {
    before: `1234.jpg.4567. 7890.1011.`,
    after: `1234 4567 7890 1011`
  },
  {
    before: `"1234.jpg 5678.jpg"`,
    after: `1234 5678`
  }
];

export { dataStr, dataFiles };
