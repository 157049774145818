import React from 'react';
import { Switch, Route } from 'react-router';
import { BrowserRouter, Redirect } from 'react-router-dom';

import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Help from './Components/Help/Help';
import Feedback from './Components/Feedback/Feedback';
import Test from './Components/Test/Test';
import Main from './Containers/Main/Main';
import s from './routes.module.scss';

export default (
  <div>
    <Header />
    <div className={s.main}>
      <BrowserRouter basename="/">
        <Switch>
          <Route path="/" component={Main} exact />
          <Route path="/help" component={Help} />
          <Route path="/feedback" component={Feedback} />
          <Route path="/test" component={Test} />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </div>
    <Footer />
  </div>
);
