import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import s from './Header.module.scss';
import logo from '../../Resources/Icons/logoPhoto.ico';
import Mobilmenu from './Mobilmenu';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
    padding: '0 5px'
  },
  title: {
    flexGrow: 1
  },
  toolbar: {
    margin: '0 auto',
    maxWidth: '1000px'
  }
}));

const NavigationBar = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <div style={{ width: '100%' }}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              href="http://ratelrc.ru"
            >
              <img src={logo} alt="" style={{ height: '45px' }} />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Rate for Lightroom Classic
            </Typography>
            <div className={s.desktopMenu}>
              <Button href="/" color="inherit">
                Главная
              </Button>
              <Button href="/help" color="inherit">
                Как пользоваться
              </Button>
              {/* <Button href="/feedback" color="inherit">
                Обратная связь
              </Button> */}
            </div>
            <Mobilmenu />
          </Toolbar>
        </div>
      </AppBar>
    </div>
  );
};

export default NavigationBar;
