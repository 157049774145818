import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 320
  }
});

const NameTable = props => {
  const classes = useStyles();

  const { filesArrMod } = props;

  if (!filesArrMod.origin.length) return null;

  return (
    <TableContainer component={Paper} style={{ marginTop: '50px' }}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left" style={{ fontWeight: '800' }}>
              #
            </TableCell>
            <TableCell align="left" style={{ fontWeight: '800' }}>
              Оригинал
            </TableCell>
            <TableCell align="left" style={{ fontWeight: '800' }}>
              Обработанное
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filesArrMod.origin.map((i, index) => (
            <TableRow key={index}>
              <TableCell align="left">{index + 1}</TableCell>
              <TableCell align="left">{i}</TableCell>
              <TableCell
                align="left"
                style={{
                  backgroundColor: /исключен:/.test(filesArrMod.modNote[index])
                    ? '#e57373'
                    : '#e8ffe9'
                }}
              >
                {filesArrMod.modNote[index]}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NameTable;
