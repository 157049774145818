const fileExtensions = ['sr2', 'cr2', 'cr3', '3fr', 'rw2', 'r3d', 'x3f'];

const checkExtensions = extensions =>
  fileExtensions.includes(extensions.toLowerCase());

// eslint-disable-next-line no-useless-escape
const regexpOnlyNumbers = /^[^\.]*[^\d](?=[\d]+)|\..*$|\D+$/g;

/**
 * | - в регулярных выражениях означает «или»
 * ^" - найти первые кавычки;
 * ^\..*$ - найти строку начинающуюся на точку
 * \D+$ - найти строку без цифр
 * \..+$ - найти расширение (последние символы с точкой)
 */
const regexp = /^"|^\..*$|\D+$|\..+$/g;

// const regexpF = /^[^\.]*?[^\d](?=[\d]{2})|\..*$|[- \(].*\..+$/g;
const regexpF = /\..+$/g;

const padStartName = (name, length) => name.padStart(length, 0);

// const removeDuplicates = arr => [...new Set(arr)];

const strToArr = str => str.split(/[ ,.:;\-|\\/]/).filter(i => i !== '');

/* Получает строку, обрабатывает и возвращает объект
  {
    origin: [],
    modNote: [],
    mod: []
  }
]
*/

// Ищет первое число самой длинной последовательной нумерации
const getFirstN = strArr => {
  let resultCount = 1;
  let resultNumber = 1;
  let firstN = 1;
  let countIndex = 1;
  let checkNumbering = 1;

  // Здесь первые подстроки с числами в начале
  // Явно перемудрил
  const arrNumbers = [];
  strArr.forEach(i => {
    const result = i.match(/^.*?\d+/);
    if (Array.isArray(result) && result.length) {
      arrNumbers.push(+result[0].replace(/[^\d]/g, ''));
    }
  });
  if (arrNumbers.length) {
    arrNumbers.forEach((i, index) => {
      if (index === 0) {
        firstN = i;
        resultNumber = i;
      } else {
        // eslint-disable-next-line no-lonely-if
        if (firstN + countIndex === i) {
          checkNumbering += 1;
          countIndex += 1;
        } else {
          if (resultCount < checkNumbering) {
            resultCount = checkNumbering;
            resultNumber = firstN;
          }
          firstN = i;
          checkNumbering = 1;
          countIndex = 1;
        }
      }
    });
  }
  return resultNumber;
};

const handleInputStr = (
  strInput,
  minNumber = 1,
  minDigits = 0,
  excludeNumbering = false
) => {
  let str = strInput;

  // удаление даты, которая в квадратных скобках
  str = str.replace(/\[.{10}.+?\]/g, '');

  if (excludeNumbering) {
    const strArr = str.split('\n');
    let nList = getFirstN(strArr);
    const newStrArr = strArr.map(i => {
      const re = new RegExp(`^(.*:|0|[^\\d])*${nList}[^\\d]`);
      if (re.test(i)) {
        nList += 1;
        return i.replace(re, '');
      }
      return i;
    });
    str = newStrArr.join('\n');
  }

  const filterMinNumber = new RegExp(`\\d{${minNumber},}`);
  // const filterMinNumber = new RegExp(/\d{1,}/);

  // замена всех пробелов, переносов, табуляции на пробел
  str = str.replace(/\n|\r|\t/g, ' ');

  // удаление url адресов
  str = str.replace(/(http|ftp)(s{0,1}):\/\/[^ ]*/g, '');

  const origin = strToArr(str)
    .filter(i => filterMinNumber.test(i))
    .map(i => {
      // чтобы отображать в исключениях расширения
      if (checkExtensions(i)) return i;
      return i.replace(regexp, '');
    });

  // Оставляем только цифры, будет использоваться для добавления нулей в начале
  const forMaxLength = strToArr(str)
    .filter(i => filterMinNumber.test(i))
    .map(i => i.replace(regexpOnlyNumbers, ''));

  let maxLength = 1;

  if (minDigits === 0) {
    // Здесь определяем количество символов в имени по самому длинному (min 4)
    maxLength = forMaxLength.reduce((acc, cur) => {
      // eslint-disable-next-line prefer-destructuring
      const length = cur.length;
      return acc > length ? acc : length;
    }, 4);
  } else {
    maxLength = minDigits;
  }

  const modNote = [];
  const mod = [];

  origin.forEach(i => {
    const newItem = padStartName(i, maxLength);
    if (modNote.includes(newItem)) {
      modNote.push(`${i} исключен: дублирует ${newItem}`);
    } else if (checkExtensions(i)) {
      modNote.push(`${i} исключен: расширение файла`);
    } else {
      modNote.push(newItem);
      mod.push(newItem);
    }
  });

  return { origin, modNote, mod };
};

const handleInputFiles = files => {
  const origin = files.map(i => i.name);
  const modNote = [];
  const mod = [];
  origin.forEach(i => {
    const newItem = i.replace(regexpF, '');
    // if (!/[0-9]/.test(i)) {
    //   modNote.push(`${i} исключен: не содержит цифры`);
    if (modNote.includes(newItem)) {
      modNote.push(`${i} исключен: дублирует ${newItem}`);
    } else {
      modNote.push(newItem);
      mod.push(newItem);
    }
  });
  return { origin, modNote, mod };
};

// разбивает массив filesArrMod с обработанными именами по разделителю separator
const joinArrMod = (filesArrMod, separator) => filesArrMod.join(separator);

export { regexp, handleInputFiles, handleInputStr, joinArrMod, strToArr };
